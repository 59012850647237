:root {
    --hd-nav-font-size: 0.875rem;
    --hd-nav-line-height: 1;
}

.hd-nav {
    display: none;
    font-size: var(--hd-nav-font-size);
    line-height: var(--hd-nav-line-height);
    overflow-x: auto;
}

@media screen and (width >= 52.25rem) {
    .hd-nav {
        display: block;
    }
}

.hd-nav__list {
    display: flex;
    list-style: none;
    gap: var(--hd-space-05);
}

.hd-nav__list-item {
    display: flex;
    border-radius: var(--hd-border-radius-sm);
    transition: background-color 0.15s ease-in, color 0.05s linear;
}

.hd-nav__link {
    text-wrap: nowrap;
    padding-inline: var(--hd-space-2);
    padding-block: var(--hd-space-1);
}

.hd-nav__link:focus-visible {
    outline: 0;
}

.hd-nav__list-item--active,
.hd-nav__list-item:hover {
    background-color: var(--hd-color-neutral-surface-weak);
}

.hd-nav__list-item:has(:focus-visible) {
    box-shadow: var(--hd-focus-ring);
}

.hd-nav__link--disabled {
    pointer-events: none;
    text-decoration: line-through;
    color: var(--hd-color-neutral-text-weakest);
}

.hd-nav__link--disabled:hover {
    background-color: transparent;
}

/* Tag */
.hd-nav__link-tag {
    border-radius: var(--hd-border-radius-sm);
    color: var(--hd-color-accent-text);
    background-color: var(--hd-color-accent-surface);
    font-size: 0.825rem;
    padding: 0.125rem var(--hd-space-05);
    margin-inline-start: var(--hd-space-05);
}
